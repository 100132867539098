import React, { Component } from "react";

import ContentLoader from "react-content-loader";
import LazyLoad from "react-lazyload";
import { NavLink } from "react-router-dom";
import ProgressiveImage from "react-progressive-image";

class PromoSlider extends Component {
    render() {
        const { slides, size, secondarySlider } = this.props;
        return (
            <React.Fragment>
                <div className={secondarySlider ? "slider-wrapper secondary-slider-wrapper my-20" : "slider-wrapper"}>
                    {slides.length === 0 ? (
                        <ContentLoader height={170} width={400} speed={1.2} primaryColor="#f3f3f3" secondaryColor="#ecebeb">
                            <rect x="20" y="0" rx="4" ry="4" width="168" height="168" />
                            <rect x="228" y="0" rx="4" ry="4" width="168" height="168" />
                        </ContentLoader>
                    ) : (
                            slides.map(slide =>
                                slide.url.indexOf("http://") === 0 || slide.url.indexOf("https://") === 0 ? (
                                    <a href={slide.url} className="slider-wrapper__img-wrapper" key={slide.id}>
                                        {/* if customURL then use anchor tag */}
                                        <LazyLoad>
                                            <ProgressiveImage delay={100} src={slide.image} placeholder={slide.image_placeholder}>
                                                {(src, loading) => (
                                                    <img
                                                        src={src}
                                                        alt={slide.name}
                                                        className="slider-wrapper__img slider-cust-img"
                                                        style={{
                                                            filter: loading ? "blur(1.2px) brightness(0.9)" : "none",
                                                            height: (12 / 5) * size + "rem",
                                                            width: (12 / 5) * size + "rem"
                                                        }}
                                                    />
                                                )}
                                            </ProgressiveImage>
                                        </LazyLoad>
                                    </a>
                                ) : (
                                        <NavLink to={"../" + slide.url} className="slider-wrapper__img-wrapper" key={slide.id}>
                                            <LazyLoad>
                                                <ProgressiveImage delay={100} src={slide.image} placeholder={slide.image_placeholder}>
                                                    {(src, loading) => (
                                                        <img
                                                            src={src}
                                                            alt={slide.name}
                                                            className="slider-wrapper__img slider-cust-img"
                                                            style={{
                                                                filter: loading ? "blur(1.2px) brightness(0.9)" : "none",
                                                                height: (12 / 5) * size + "rem",
                                                                width: (12 / 5) * size + "rem"
                                                            }}
                                                        />
                                                    )}
                                                </ProgressiveImage>
                                            </LazyLoad>
                                        </NavLink>
                                    )
                            )
                        )}
                </div>
            </React.Fragment>
        );
    }
}

export default PromoSlider;
