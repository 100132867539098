import { GET_PAYMENT_GATEWAYS, GET_STRIPE_PUBLIC_KEY } from "./actionTypes";

const initialState = {
    paymentgateways: [],
    stripePublicKey: String
};

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_PAYMENT_GATEWAYS:
            return { ...state, paymentgateways: action.payload };
        case GET_STRIPE_PUBLIC_KEY:
            return { ...state, stripePublicKey: action.payload };
        default:
            return state;
    }
}
