import React, { Component } from "react";

import ContentLoader from "react-content-loader";
import { NavLink } from "react-router-dom";
import ProgressiveImage from "react-progressive-image";
import Carousel from 'nuka-carousel';

const properties = {
    autoplay: true,
    wrapAround: true,
    easing: 'easeCubic',
    edgeEasing: 'easeCubic',
    transitionMode: 'scroll3d',
    heightMode: 'first'
}

class PromoSlideshow extends Component {

    componentDidMount() {
        setTimeout(() => {
            this.carouselRef.setDimensions();
        }, 500);
    }

    render() {
        const { slides } = this.props;
        return (
            <React.Fragment>
                <div>
                    {slides.length === 0 ? (
                        <ContentLoader height={400} width="100%" speed={1.2} primaryColor="#f3f3f3" secondaryColor="#ecebeb">
                            <rect x="20" y="0" rx="4" ry="4" width="168" height="168" />
                            <rect x="228" y="0" rx="4" ry="4" width="168" height="168" />
                        </ContentLoader>
                    ) : (
                            <Carousel {...properties} ref={node => { this.carouselRef = node }} className="slideshow" renderCenterLeftControls={({ previousSlide }) => (
                                <span></span>
                            )}
                                renderCenterRightControls={({ nextSlide }) => (
                                    <span></span>
                                )}>
                                {slides.map(slide =>
                                    slide.url.indexOf("http://") === 0 || slide.url.indexOf("https://") === 0 ? (
                                        <a href={slide.url} className="slider-wrapper__img-wrapper" key={slide.id}>
                                            {/* if customURL then use anchor tag */}
                                            <ProgressiveImage delay={0} src={slide.image} placeholder={slide.image_placeholder}>
                                                {(src, loading) => (
                                                    <img
                                                        src={src}
                                                        alt={slide.name}
                                                        className="slider-wrapper__img slider-cust-img"
                                                        style={{
                                                            filter: loading ? "blur(1.2px) brightness(0.9)" : "none",
                                                            width: "100%"
                                                        }}
                                                    />
                                                )}
                                            </ProgressiveImage>
                                        </a>
                                    ) : (
                                            <NavLink to={"../" + slide.url} className="" key={slide.id}>
                                                <ProgressiveImage delay={0} src={slide.image} placeholder={slide.image_placeholder}>
                                                    {(src, loading) => (
                                                        <img
                                                            src={src}
                                                            alt={slide.name}
                                                            className=""
                                                            style={{
                                                                filter: loading ? "blur(1.2px) brightness(0.9)" : "none",
                                                                width: "100%"
                                                            }}
                                                        />
                                                    )}
                                                </ProgressiveImage>
                                            </NavLink>
                                        )
                                )}
                            </Carousel>
                        )}
                </div>
            </React.Fragment>
        );
    }
}

export default PromoSlideshow;
