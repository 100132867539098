import { WEBSITE_URL } from "./website";

export const GET_SETTINGS_URL = WEBSITE_URL + "/api/get-settings";
export const SEARCH_LOCATIONS_URL = WEBSITE_URL + "/api/search-location";
export const GET_POPULAR_LOCATIONS_URL = WEBSITE_URL + "/api/popular-geo-locations";
export const GET_PROMO_SLIDER_URL = WEBSITE_URL + "/api/promo-slider";
export const GET_CATEGORIES_SLIDER_URL = WEBSITE_URL + "/api/get-restaurant-categories";
export const GET_RESTAURANT_BY_CATEGORY_URL = WEBSITE_URL + "/api/get-restaurants-from-category";
export const GET_DELIVERY_RESTAURANTS_URL = WEBSITE_URL + "/api/get-delivery-restaurants";
export const GET_SELFPICKUP_RESTAURANTS_URL = WEBSITE_URL + "/api/get-selfpickup-restaurants";
export const GET_RESTAURANT_INFO_URL = WEBSITE_URL + "/api/get-restaurant-info";
export const GET_RESTAURANT_INFO_BY_ID_URL = WEBSITE_URL + "/api/get-restaurant-info-by-id";
export const GET_RESTAURANT_ITEMS_URL = WEBSITE_URL + "/api/get-restaurant-items";
export const APPLY_COUPON_URL = WEBSITE_URL + "/api/apply-coupon";
export const LOGIN_USER_URL = WEBSITE_URL + "/api/login";
export const REGISTER_USER_URL = WEBSITE_URL + "/api/register";
export const GET_PAGES_URL = WEBSITE_URL + "/api/get-pages";
export const GET_SINGLE_PAGE_URL = WEBSITE_URL + "/api/get-single-page";
export const SEARCH_RESTAURANTS_URL = WEBSITE_URL + "/api/search-restaurants";
export const GET_ADDRESSES_URL = WEBSITE_URL + "/api/get-addresses";
export const SAVE_ADDRESS_URL = WEBSITE_URL + "/api/save-address";
export const DELETE_ADDRESS_URL = WEBSITE_URL + "/api/delete-address";
export const UPDATE_USER_INFO_URL = WEBSITE_URL + "/api/update-user-info";
export const PLACE_ORDER_URL = WEBSITE_URL + "/api/place-order";
export const SET_DEFAULT_URL = WEBSITE_URL + "/api/set-default-address";
export const GET_ORDERS_URL = WEBSITE_URL + "/api/get-orders";
export const GET_PAYMENT_GATEWAYS_URL = WEBSITE_URL + "/api/get-payment-gateways";
export const GET_STRIPE_PUBLIC_KEY_URL = WEBSITE_URL + "/api/stripe-pub-key";
export const NOTIFICATION_TOKEN_URL = WEBSITE_URL + "/api/save-notification-token";
export const SEND_OTP_URL = WEBSITE_URL + "/api/send-otp";
export const VERIFY_OTP_URL = WEBSITE_URL + "/api/verify-otp";
export const RAZORPAY_PAYMENT_URL = WEBSITE_URL + "/api/payment/process-razor-pay/";
export const CHECK_USER_RUNNING_ORDER_URL = WEBSITE_URL + "/api/check-running-order";
export const GET_ORDER_CANCEL_URL = WEBSITE_URL + "/api/cancel-order";
export const GET_WALLET_TRANSACTIONS_URL = WEBSITE_URL + "/api/get-wallet-transactions";
export const CHECK_RESTAURANT_OPERATION_SERVICE_URL = WEBSITE_URL + "/api/check-restaurant-operation-service";
export const GET_SINGLE_ITEM_URL = WEBSITE_URL + "/api/get-single-item";
export const GET_ALL_LANGUAGES_URL = WEBSITE_URL + "/api/get-all-languages";
export const GET_SINGLE_LANGUAGE_DATA_URL = WEBSITE_URL + "/api/get-single-language";
export const GET_ADDRESS_FROM_COORDINATES = WEBSITE_URL + "/api/coordinate-to-address";
export const UPDATE_USER_INFO_GENERAL_URL = WEBSITE_URL + "/api/update-user";
/* Delivery URLs */
export const LOGIN_DELIVERY_USER_URL = WEBSITE_URL + "/api/delivery/login";
export const UPDATE_DELIVERY_USER_INFO_URL = WEBSITE_URL + "/api/delivery/update-user-info";
export const GET_DELIVERY_ORDERS_URL = WEBSITE_URL + "/api/delivery/get-delivery-orders";
export const GET_SINGLE_DELIVERY_ORDER_URL = WEBSITE_URL + "/api/delivery/get-single-delivery-order";
export const SEND_DELIVERY_GUY_GPS_LOCATION_URL = WEBSITE_URL + "/api/delivery/set-delivery-guy-gps-location";
export const GET_DELIVERY_GUY_GPS_LOCATION_URL = WEBSITE_URL + "/api/delivery/get-delivery-guy-gps-location";
export const ACCEPT_TO_DELIVER_URL = WEBSITE_URL + "/api/delivery/accept-to-deliver";
export const PICKEDUP_ORDER_URL = WEBSITE_URL + "/api/delivery/pickedup-order";
export const DELIVER_ORDER_URL = WEBSITE_URL + "/api/delivery/deliver-order";
