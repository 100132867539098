import { CHECK_RESTAURANT_OPERATION_SERVICE } from "./actionTypes";

const initialState = {
    is_operational: ""
};

export default function(state = initialState, action) {
    switch (action.type) {
        case CHECK_RESTAURANT_OPERATION_SERVICE:
            return { ...state, is_operational: action.payload };
        default:
            return state;
    }
}
