export const isRestaurantClosed = (restaurant) => {
    const todaySchedule = getTodayRestaurantTimingWithoutCheckPeriod(restaurant);
    if (todaySchedule.length === 1) return todaySchedule[0].closed === 1;
    else if (!restaurantHaveHours(restaurant)) return true;
    else return false;
}

export const isRestaurantInStandby = (restaurant) => {
    const todaySchedule = getTodayRestaurantTiming(restaurant);
    if (todaySchedule.length === 1) {
        const timeSlots = getTimeSlots(todaySchedule[0]);
        const currentTime = new Date();
        if (currentTime >= timeSlots[0] && currentTime <= timeSlots[1]) {
            return false;
        }
        else if (currentTime >= timeSlots[2] && currentTime <= timeSlots[3]) {
            return false;
        }
    }
    return true;
}

export const getTodayRestaurantTimingWithoutCheckPeriod = (restaurant) => {
    const today = getCurrentDayOfWeek();
    const todaySchedule = restaurant.hours.filter(timing => {
        if (timing.day_of_week && timing.day_of_week.toLowerCase() === today) {
            return timing;
        }
    })
    return todaySchedule;
}

export const getTodayRestaurantTiming = (restaurant) => {
    const today = getCurrentDayOfWeek();
    const todaySchedule = restaurant.hours.filter(timing => {
        if (timing.day_of_week && timing.day_of_week.toLowerCase() === today) {
            if (timing.start_first_period !== null
                && timing.end_first_period !== null
                && timing.start_second_period !== null
                && timing.end_second_period !== null
            ) {
                return timing;
            }
        }
    })
    return todaySchedule;
}

const getCurrentDayOfWeek = () => {
    var dayOfWeek = new Date().getDay();
    return isNaN(dayOfWeek) ? null : ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'][dayOfWeek];
}

const getTimeSlots = (schedule) => {
    // schedule.start_first_period
    const date_start_first_period = new Date().setHours(parseInt(schedule.start_first_period.split(':')[0]), schedule.start_first_period.split(':')[1]);
    const date_end_first_period = new Date().setHours(parseInt(schedule.end_first_period.split(':')[0]), schedule.end_first_period.split(':')[1]);
    const date_start_second_period = new Date().setHours(parseInt(schedule.start_second_period.split(':')[0]), schedule.start_second_period.split(':')[1]);
    const date_end_second_period = new Date().setHours(parseInt(schedule.end_second_period.split(':')[0]), schedule.end_second_period.split(':')[1]);
    return [
        date_start_first_period,
        date_end_first_period,
        date_start_second_period,
        date_end_second_period
    ]
}

const restaurantHaveHours = (restaurant) => {
    const todaySchedule = getTodayRestaurantTiming(restaurant);
    if (todaySchedule.length === 1) {
        if (todaySchedule[0].start_first_period !== null
            && todaySchedule[0].end_first_period !== null
            && todaySchedule[0].start_second_period !== null
            && todaySchedule[0].end_second_period !== null
        ) {
            return false;
        }
        return false;
    }
    return true;
}