import { GET_DELIVERY_RESTAURANTS_URL, GET_SELFPICKUP_RESTAURANTS_URL } from "../../../../configs/index";
import React, { Component } from "react";

import ContentLoader from "react-content-loader";
import DelayLink from "../../../helpers/delayLink";
import {
    isRestaurantClosed, isRestaurantInStandby,
    getTodayRestaurantTiming
} from "../../../helpers/restaurantTime";
import Ink from "react-ink";
import LazyLoad from "react-lazyload";
import ProgressiveImage from "react-progressive-image";

import axios from "axios";
import PromoSlider from "../PromoSlider";
import CategoriesSlider from "../CategoriesSlider";

class RestaurantList extends Component {
    state = {
        total: null,
        restaurants: [],
        loading: false,
        loading_more: true,
        selfpickup: false,
        userPreferredSelectionDelivery: true,
        userPreferredSelectionSelfPickup: false,
        no_restaurants: false
    };

    componentDidMount() {
        if (localStorage.getItem("userPreferredSelection") === "DELIVERY") {
            this.setState({ userPreferredSelectionDelivery: true });
            this.filterDelivery();
        }
        if (localStorage.getItem("userPreferredSelection") === "SELFPICKUP" && localStorage.getItem("enSPU") === "true") {
            this.setState({ userPreferredSelectionSelfPickup: true });
            this.filterSelfPickup();
        } else {
            localStorage.setItem("userPreferredSelection", "DELIVERY");
            localStorage.setItem("userSelected", "DELIVERY");
            this.setState({ userPreferredSelectionDelivery: true });
            this.getDeliveryRestaurants();
        }
    }

    getDeliveryRestaurants = () => {
        if (localStorage.getItem("userSetAddress")) {
            if (!this.state.loading) {
                // Set loading state to true to
                // avoid multiple requests on scroll
                this.setState({
                    loading: true
                });

                const userSetAddress = JSON.parse(localStorage.getItem("userSetAddress"));
                // make XHR request
                axios
                    .post(GET_DELIVERY_RESTAURANTS_URL, {
                        latitude: userSetAddress.lat,
                        longitude: userSetAddress.lng
                    })
                    .then(response => {
                        const restaurants = response.data;
                        if (restaurants.length) {
                            // add new
                            this.setState({
                                total: restaurants.length,
                                restaurants: [...this.state.restaurants, ...restaurants],
                                loading: false,
                                loading_more: false,
                                no_restaurants: false
                            });
                        } else {
                            this.setState({
                                total: null,
                                loading: false,
                                loading_more: false,
                                no_restaurants: true
                            });
                        }
                    });
            }
        }
    };

    getSelfPickupRestaurants = () => {
        if (localStorage.getItem("userSetAddress")) {
            if (!this.state.loading) {
                // Set loading state to true to
                // avoid multiple requests on scroll
                this.setState({
                    loading: true
                });

                const userSetAddress = JSON.parse(localStorage.getItem("userSetAddress"));

                // make XHR request
                axios
                    .post(GET_SELFPICKUP_RESTAURANTS_URL, {
                        latitude: userSetAddress.lat,
                        longitude: userSetAddress.lng
                    })
                    .then(response => {
                        const restaurants = response.data;
                        if (restaurants.length) {
                            // console.log("RES from API", restaurants);
                            // add new
                            this.setState({
                                total: restaurants.length,
                                restaurants: [...this.state.restaurants, ...restaurants],
                                loading: false,
                                loading_more: false,
                                no_restaurants: false
                            });
                        } else {
                            this.setState({
                                total: null,
                                loading: false,
                                loading_more: false,
                                no_restaurants: true
                            });
                        }
                    });
            }
        }
    };

    filterDelivery = () => {
        this.setState(
            () => ({
                selfpickup: false,
                restaurants: [],
                userPreferredSelectionDelivery: true,
                userPreferredSelectionSelfPickup: false
            }),
            () => {
                this.getDeliveryRestaurants();
            }
        );
        localStorage.setItem("userPreferredSelection", "DELIVERY");
        if (localStorage.getItem("showPromoSlider") === "true") {
            // window.scrollTo({ top: 205, behavior: "smooth" });
        } else {
            // window.scrollTo({ top: 0, behavior: "smooth" });
        }
    };

    filterSelfPickup = () => {
        this.setState(
            () => ({
                selfpickup: true,
                restaurants: [],
                userPreferredSelectionSelfPickup: true,
                userPreferredSelectionDelivery: false
            }),
            () => {
                this.getSelfPickupRestaurants();
            }
        );
        localStorage.setItem("userPreferredSelection", "SELFPICKUP");
        if (localStorage.getItem("showPromoSlider") === "true") {
            // window.scrollTo({ top: 205, behavior: "smooth" });
        } else {
            // window.scrollTo({ top: 0, behavior: "smooth" });
        }
    };

    getFormattedRestaurantOpeningTime = (restaurant, standby) => {
        if (isRestaurantClosed(restaurant)) return;
        if (restaurant.hours.length > 0) {
            const todaySchedule = getTodayRestaurantTiming(restaurant);
            if (todaySchedule.length === 1) {
                if (standby) {
                    return (<div>
                        <div><span>Fechado</span></div>
                        <div className="py-5 time-slots">
                            <i className="si si-clock pr-1" /><span>
                                {`${todaySchedule[0].start_first_period} - ${todaySchedule[0].end_first_period} | ${todaySchedule[0].start_second_period} - ${todaySchedule[0].end_second_period}`}
                            </span>
                        </div>
                    </div>)
                }
                else {
                    return (<div className="py-5 time-slots"><i className="si si-clock pr-1" /> <span>
                        {`${todaySchedule[0].start_first_period} - ${todaySchedule[0].end_first_period} | ${todaySchedule[0].start_second_period} - ${todaySchedule[0].end_second_period}`}
                    </span></div>)
                }
            }
        }
        else {
            return (<div><i className="si si-clock pr-1" /> <span>Não há informação sobre o horário</span></div>)
        }
    }

    getFormattedRestaurantClosed = (restaurant) => {
        if (isRestaurantClosed(restaurant)) return <span>Fechado</span>;
    }

    render() {
        return (
            <React.Fragment>
                <div className={`bg-white mb-100 flex-wrap display-flex`}>
                    {this.state.no_restaurants && (
                        <div
                            className={"mt-20 " + (localStorage.getItem("enSPU") === "true" ? "sticky-top" : "pt-3")}
                            style={{ backgroundColor: "#FAFAFA" }}
                        >
                            {localStorage.getItem("enSPU") === "true" ? (
                                <React.Fragment>
                                    <div className="px-15 py-3 d-flex justify-content-between align-items-center">
                                        <h1 className="restaurant-count mb-0 mr-2">{localStorage.getItem("noRestaurantMessage")}</h1>
                                        <div className="d-flex">
                                            <button
                                                onClick={this.filterDelivery}
                                                className={
                                                    "btn btn-preference mr-2 " + (this.state.userPreferredSelectionDelivery ? "user-preferred" : "")
                                                }
                                            >
                                                {localStorage.getItem("deliveryTypeDelivery")}
                                                {this.state.userPreferredSelectionDelivery && <i className="si si-check ml-2"></i>}
                                            </button>
                                            <button
                                                onClick={this.filterSelfPickup}
                                                className={
                                                    "btn btn-preference " + (this.state.userPreferredSelectionSelfPickup ? "user-preferred" : "")
                                                }
                                            >
                                                {localStorage.getItem("deliveryTypeSelfPickup")}
                                                {this.state.userPreferredSelectionSelfPickup && <i className="si si-check ml-2"></i>}
                                            </button>
                                        </div>
                                    </div>
                                    <hr />
                                </React.Fragment>
                            ) : (
                                    <h1 className="restaurant-count mb-0 mr-2 px-15">{localStorage.getItem("noRestaurantMessage")}</h1>
                                )}
                            <hr />
                        </div>
                    )}
                    {this.state.total ? (
                        <React.Fragment>
                            <div
                                className={"mt-20 " + (localStorage.getItem("enSPU") === "true" ? "sticky-top" : "pt-3")}
                                style={{ backgroundColor: "#FAFAFA" }}
                            >
                                {localStorage.getItem("enSPU") === "true" && (
                                    <React.Fragment>
                                        <div className="px-15 py-3 d-flex justify-content-between align-items-center">
                                            <h1 className="restaurant-count mb-0 mr-2">
                                                {this.state.total} {localStorage.getItem("restaurantCountText")}
                                            </h1>
                                            <div className="d-flex">
                                                <button
                                                    onClick={this.filterDelivery}
                                                    className={
                                                        "btn btn-preference mr-2 " +
                                                        (this.state.userPreferredSelectionDelivery ? "user-preferred" : "")
                                                    }
                                                >
                                                    {localStorage.getItem("deliveryTypeDelivery")}
                                                    {this.state.userPreferredSelectionDelivery && <i className="si si-check ml-2"></i>}
                                                </button>
                                                <button
                                                    onClick={this.filterSelfPickup}
                                                    className={
                                                        "btn btn-preference " + (this.state.userPreferredSelectionSelfPickup ? "user-preferred" : "")
                                                    }
                                                >
                                                    {localStorage.getItem("deliveryTypeSelfPickup")}
                                                    {this.state.userPreferredSelectionSelfPickup && <i className="si si-check ml-2"></i>}
                                                </button>
                                            </div>
                                        </div>
                                    </React.Fragment>
                                )}
                            </div>
                        </React.Fragment>
                    ) : (
                            <ContentLoader height={60} width={400} speed={1.2} primaryColor="#f3f3f3" secondaryColor="#ecebeb">
                                <rect x="20" y="25" rx="0" ry="0" width="155" height="18" />
                            </ContentLoader>
                        )}
                    {this.state.restaurants.length === 0 ? (
                        <ContentLoader height={378} width={400} speed={1.2} primaryColor="#f3f3f3" secondaryColor="#ecebeb">
                            <rect x="20" y="20" rx="4" ry="4" width="80" height="78" />
                            <rect x="144" y="30" rx="0" ry="0" width="115" height="18" />
                            <rect x="144" y="60" rx="0" ry="0" width="165" height="16" />

                            <rect x="20" y="145" rx="4" ry="4" width="80" height="78" />
                            <rect x="144" y="155" rx="0" ry="0" width="115" height="18" />
                            <rect x="144" y="185" rx="0" ry="0" width="165" height="16" />

                            <rect x="20" y="270" rx="4" ry="4" width="80" height="78" />
                            <rect x="144" y="280" rx="0" ry="0" width="115" height="18" />
                            <rect x="144" y="310" rx="0" ry="0" width="165" height="16" />
                        </ContentLoader>
                    ) : (
                            this.state.restaurants.map((restaurant, index) => {
                                const closed = isRestaurantClosed(restaurant);
                                const standby = closed ? true : isRestaurantInStandby(restaurant);
                                return (
                                    <React.Fragment key={restaurant.id}>
                                        <div className={`col-xs-12 col-sm-12 ${index > 0 ? 'col-lg-6' : ''}  restaurant-block `}>
                                            <DelayLink
                                                to={"../restaurants/" + restaurant.slug}
                                                delay={200}
                                                className="block text-center mb-3"
                                                clickAction={() => {
                                                    localStorage.getItem("userPreferredSelection") === "DELIVERY" &&
                                                        restaurant.delivery_type === 1 &&
                                                        localStorage.setItem("userSelected", "DELIVERY");
                                                    localStorage.getItem("userPreferredSelection") === "SELFPICKUP" &&
                                                        restaurant.delivery_type === 2 &&
                                                        localStorage.setItem("userSelected", "SELFPICKUP");
                                                    localStorage.getItem("userPreferredSelection") === "DELIVERY" &&
                                                        restaurant.delivery_type === 3 &&
                                                        localStorage.setItem("userSelected", "DELIVERY");
                                                    localStorage.getItem("userPreferredSelection") === "SELFPICKUP" &&
                                                        restaurant.delivery_type === 3 &&
                                                        localStorage.setItem("userSelected", "SELFPICKUP");
                                                }}
                                            >
                                                <div
                                                    className={`block-content block-content-full ${restaurant.is_featured ? "ribbon ribbon-bookmark ribbon-warning pt-2" : "pt-2"
                                                        } `}
                                                >
                                                    {restaurant.is_featured ? (
                                                        <div className="ribbon-box">{localStorage.getItem("restaurantFeaturedText")}</div>
                                                    ) : null}

                                                    {/* <LazyLoad> */}
                                                    <ProgressiveImage delay={100} src={restaurant.image} placeholder={restaurant.placeholder_image}>
                                                        {(src, loading) => (
                                                            <img
                                                                src={src}
                                                                alt={restaurant.name}
                                                                className="restaurant-image"
                                                                style={{
                                                                    filter: loading ? "blur(1.2px) brightness(0.9)" : "none"
                                                                }}
                                                            />
                                                        )}
                                                    </ProgressiveImage>
                                                    {/* </LazyLoad> */}
                                                </div>
                                                <div className={`block-content block-content-full restaurant-info ${(closed || standby) ? 'r-closed' : 'bg-light'}`}>
                                                    <div className={`font-w600 fixedRestaurantName ${(closed || standby) ? 'text-bright' : 'text-orange'} `}>{restaurant.name.toUpperCase()}</div>
                                                    {(closed || standby) ? '' : (<div className={`font-size-sm truncate-text ${(closed || standby) ? 'text-bright' : 'text-dark'}`}>{restaurant.description}</div>)}
                                                    <div className={`mh-12 font-size-xs truncate-text ${(closed || standby) ? 'text-bright' : 'text-muted'}`}>
                                                        {this.getFormattedRestaurantClosed(restaurant)}
                                                        {this.getFormattedRestaurantOpeningTime(restaurant, standby)}
                                                    </div>
                                                    <hr />
                                                    <div className={`text-center restaurant-meta mt-5 d-flex align-items-center justify-content-between ${(closed || standby) ? 'text-bright' : 'text-muted'}`}>
                                                        <div className="col-2 p-0 text-left">
                                                            <i
                                                                className="fa fa-star pr-1"
                                                                style={{
                                                                    color: localStorage.getItem("storeColor")
                                                                }}
                                                            />{" "}
                                                            {restaurant.rating}
                                                        </div>
                                                        <div className="col-4 p-0 text-center">
                                                            <i className="si si-clock pr-1" /> {restaurant.delivery_time}{" "}
                                                            {localStorage.getItem("homePageMinsText")}
                                                        </div>
                                                        <div className="col-6 p-0 text-center">
                                                            <i className="si si-wallet pr-1" />
                                                            {restaurant.price_range}{localStorage.getItem("currencyFormat")} {localStorage.getItem("homePageForTwoText")}
                                                        </div>
                                                    </div>
                                                </div>
                                                <Ink duration="500" hasTouch={false} />
                                            </DelayLink>
                                        </div>
                                        <React.Fragment>
                                            {/* Display categories slider */}
                                            {index < 1 && (
                                                <React.Fragment>
                                                    <div className="block-section block-content-full bg-light-gray mb-15 slider-wrapper">
                                                        <h2 className="block-title text-orange section-title fixedRestaurantName">ESCOLHA POR CATEGORIAS</h2>
                                                        <CategoriesSlider
                                                            slides={this.props.categories_slides}
                                                            size={this.props.categories_slides.length}
                                                            secondarySlider={true}
                                                        />
                                                    </div>
                                                </React.Fragment>)}
                                        </React.Fragment>
                                        {localStorage.getItem("showPromoSlider") === "true" && (
                                            <React.Fragment>
                                                {this.props.slides && this.props.slides.length > 0 && (
                                                    <React.Fragment>
                                                        {index === this.props.slides[0]["promo_slider"]["position_id"] - 1 && (
                                                            <PromoSlider
                                                                slides={this.props.slides}
                                                                size={this.props.slides[0]["promo_slider"]["size"]}
                                                                secondarySlider={true}
                                                            />
                                                        )}
                                                    </React.Fragment>
                                                )}
                                            </React.Fragment>
                                        )}
                                    </React.Fragment>
                                )
                            })
                        )}
                    {this.state.loading_more ? (
                        <div className="">
                            <ContentLoader height={120} width={400} speed={1.2} primaryColor="#f3f3f3" secondaryColor="#ecebeb">
                                <rect x="20" y="20" rx="4" ry="4" width="80" height="78" />
                                <rect x="144" y="35" rx="0" ry="0" width="115" height="18" />
                                <rect x="144" y="65" rx="0" ry="0" width="165" height="16" />
                            </ContentLoader>
                        </div>
                    ) : null}
                </div>
            </React.Fragment>
        );
    }
}

export default RestaurantList;
