import * as serviceWorker from "./serviceWorker";

import { Helmet } from "react-helmet";
import { Provider } from "react-redux";
import React from "react";
import store from "./services/store";

const Root = ({ children, initialState = {} }) => (
    <React.Fragment>
        {localStorage.getItem("customCSS") !== "NULL" && (
            <Helmet>
                <style type="text/css">{localStorage.getItem("customCSS")}</style>
            </Helmet>
        )}
        <Provider store={store(initialState)}>{children}</Provider>
    </React.Fragment>
);
// if we are on cordova don't register the service worker
if (!window.cordova) {
    serviceWorker.register();
}
// serviceWorker.unregister();

export default Root;
