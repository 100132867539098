import React, { Component } from "react";

import ContentLoader from "react-content-loader";
import LazyLoad from "react-lazyload";
import { NavLink } from "react-router-dom";
import ProgressiveImage from "react-progressive-image";

class CategoriesSlider extends Component {
    constructor(props) {
        super(props)
        this.slider_wrapper = React.createRef()
    }

    horizontalScroll = (e) => {
        const width = (this.slider_wrapper.current.offsetWidth);
        this.slider_wrapper.current.scrollTo({
            left: this.slider_wrapper.current.scrollLeft + (e.deltaY * (width / 100)),
            behaviour: 'smooth'
        })
    }

    render() {
        const { slides, size } = this.props;
        return (
            <React.Fragment>
                <div className="slider-wrapper" ref={this.slider_wrapper} onWheel={this.horizontalScroll}>
                    {slides.length === 0 ? (
                        <ContentLoader height={170} width={400} speed={1.2} primaryColor="#f3f3f3" secondaryColor="#ecebeb">
                            <rect x="20" y="0" rx="4" ry="4" width="168" height="168" />
                            <rect x="228" y="0" rx="4" ry="4" width="168" height="168" />
                        </ContentLoader>
                    ) : (
                            slides.map(slide =>
                                slide.slug.indexOf("http://") === 0 || slide.slug.indexOf("https://") === 0 ? (
                                    <a href={slide.slug} className="slider-wrapper__img-wrapper" key={slide.id}>
                                        {/* if customURL then use anchor tag */}
                                        {/* <LazyLoad> */}
                                        <ProgressiveImage delay={100} src={slide.image} placeholder={slide.thumbnail}>
                                            {(src, loading) => (
                                                <img
                                                    src={src}
                                                    alt={slide.slug}
                                                    className="slider-wrapper__img slider-cust-img"
                                                    style={{
                                                        filter: loading ? "blur(1.2px) brightness(0.9)" : "none",
                                                        height: (12 / 10) * size + "rem",
                                                        width: (12 / 10) * size + "rem"
                                                    }}
                                                />
                                            )}
                                        </ProgressiveImage>
                                        {/* </LazyLoad> */}
                                    </a>
                                ) : (
                                        <NavLink to={`../restaurants-by-category/${slide.id}`} className="slider-wrapper__img-wrapper" key={slide.id}>
                                            {/* <LazyLoad> */}
                                            <ProgressiveImage delay={100} src={slide.image} placeholder={slide.thumbnail}>
                                                {(src, loading) => (
                                                    <img
                                                        src={src}
                                                        alt={slide.slug}
                                                        className="slider-wrapper__img slider-cust-img"
                                                        style={{
                                                            filter: loading ? "blur(1.2px) brightness(0.9)" : "none",
                                                            height: (12 / 10) * size + "rem",
                                                            width: (12 / 10) * size + "rem"
                                                        }}
                                                    />
                                                )}
                                            </ProgressiveImage>
                                            {/* </LazyLoad> */}
                                        </NavLink>
                                    )
                            )
                        )}
                </div>
            </React.Fragment>
        );
    }
}

export default CategoriesSlider;
