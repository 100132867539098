import {
    LOGIN_USER,
    REGISTER_USER,
    LOGOUT_USER,
    UPDATE_USER_INFO,
    UPDATE_USER_INFO_GENERAL,
    SEND_OTP,
    VERIFY_OTP,
    RUNNING_ORDER,
    GET_WALLET_TRANSACTIONS
} from "./actionTypes";

const initialState = {
    user: [],
    running_order: false,
    wallet: []
};

export default function (state = initialState, action) {
    switch (action.type) {
        case LOGIN_USER:
            return { ...state, user: action.payload };
        case REGISTER_USER:
            return { ...state, user: action.payload };
        case LOGOUT_USER:
            return { ...state, user: action.payload };
        case UPDATE_USER_INFO:
            return { ...state, user: action.payload };
        case UPDATE_USER_INFO_GENERAL:
            return { ...state, user: action.payload };
        case SEND_OTP:
            return { ...state, user: action.payload };
        case VERIFY_OTP:
            return { ...state, user: action.payload };
        case RUNNING_ORDER:
            return { ...state, running_order: action.payload };
        case GET_WALLET_TRANSACTIONS:
            return { ...state, wallet: action.payload };
        default:
            return state;
    }
}
