import React, { Component } from "react";
import { IMAGES_URL } from "../../configs/website";

class Loading extends Component {
    render() {
        return (
            <React.Fragment>
                <div className="height-100 overlay-loading container-max-fluid">
                    <div>
                        <img src={`${IMAGES_URL}/assets/img/loading-food.gif`} alt={localStorage.getItem("pleaseWaitText")} />
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default Loading;
