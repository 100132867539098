import * as firebase from "firebase/app";

import React, { Component } from "react";

import Footer from "../Footer";
import Meta from "../../helpers/meta";
import Nav from "../Nav";
import { Redirect } from "react-router";
import RestaurantList from "./RestaurantList";
import { connect } from "react-redux";
import { getPromoSlides } from "../../../services/promoSlider/actions";
import { getCategoriesSlides } from "../../../services/categoriesSlider/actions";
import { getSettings } from "../../../services/settings/actions";
import messaging from "../../../init-fcm";
import { saveNotificationToken } from "../../../services/notification/actions";
import { getSingleLanguageData } from "../../../services/translations/actions";
import Slide from "react-reveal/Slide";
import PromoSlideshow from "./PromoSlideshow";

class Home extends Component {
    static contextTypes = {
        router: () => null
    };

    async componentDidMount() {
        this.props.getSettings();
        //if currentLocation doesnt exists in localstorage then redirect the user to firstscreen
        //else make API calls
        if (localStorage.getItem("userSetAddress") !== null) {
            // this.context.router.history.push("/search-location");
            // console.log("Redirect to search location");
            // return <Redirect to="/search-location" />;
            this.props.getPromoSlides();
            this.props.getCategoriesSlides();
        } else {
            //call to promoSlider API to fetch the slides
        }

        const { user } = this.props;
        if (user.success) {
            if (firebase.messaging.isSupported()) {
                let handler = this.props.saveNotificationToken;
                messaging
                    .requestPermission()
                    .then(async function () {
                        const push_token = await messaging.getToken();
                        handler(push_token, user.data.id, user.data.auth_token);
                    })
                    .catch(function (err) {
                        console.log("Unable to get permission to notify.", err);
                    });
                if (navigator.serviceWorker !== undefined)
                    navigator.serviceWorker.addEventListener("message", message => console.log(message));
            } else {
                if (window.ReactNativeWebView) {
                    console.log("Here");
                    window.ReactNativeWebView.postMessage(user.data.id);
                    if (window.navigator.notification) {
                        navigator.notification.confirm(
                            "Not Supported",
                            () => { },
                            '',
                            ['Ok']
                        );
                    } else {
                        alert("Not Supported");
                    }
                }
            }
        }

        if (localStorage.getItem("state") !== null) {
            const languages = JSON.parse(localStorage.getItem("state")).languages;
            if (languages && languages.length > 0) {
                if (localStorage.getItem("multiLanguageSelection") === "true") {
                    if (localStorage.getItem("userPreferedLanguage")) {
                        this.props.getSingleLanguageData(localStorage.getItem("userPreferedLanguage"));
                        // console.log("Called 1");
                    } else {
                        if (languages.length) {
                            const id = languages.filter(lang => lang.is_default === 1)[0].id;
                            this.props.getSingleLanguageData(id);
                        }
                    }
                } else {
                    // console.log("Called 2");
                    if (languages.length) {
                        const id = languages.filter(lang => lang.is_default === 1)[0].id;
                        this.props.getSingleLanguageData(id);
                    }
                }
            }
        }
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.languages !== nextProps.languages) {
        }
    }

    componentWillUnmount() {
        if (navigator.serviceWorker !== undefined)
            navigator.serviceWorker.removeEventListener("message", message => console.log(message));
    }

    render() {

        if (localStorage.getItem("userSetAddress") === null) {
            // this.context.router.history.push("/search-location");
            console.log("Redirect to search location");
            return <Redirect to="/search-location" />;
        }

        const { history, user, promo_slides, categories_slides } = this.props;

        // console.log(promo_slides.mainSlides.length);

        return (
            <React.Fragment>
                <Meta
                    seotitle={localStorage.getItem("seoMetaTitle")}
                    seodescription={localStorage.getItem("seoMetaDescription")}
                    ogtype="website"
                    ogtitle={localStorage.getItem("seoOgTitle")}
                    ogdescription={localStorage.getItem("seoOgDescription")}
                    ogurl={window.location.href}
                    twittertitle={localStorage.getItem("seoTwitterTitle")}
                    twitterdescription={localStorage.getItem("seoTwitterDescription")}
                />
                <div className="height-100-percent bg-white mb-50">
                    <Nav logo={true} active_nearme={true} disable_back_button={true} history={history} loggedin={user.success} />
                    {/* Passing slides as props to PromoSlider */}
                    {localStorage.getItem("showPromoSlider") === "true" && (
                        <React.Fragment>
                            {promo_slides && promo_slides.mainSlides && promo_slides.mainSlides.length > 0 && (
                                <PromoSlideshow slides={promo_slides.mainSlides} size={promo_slides.mainSlides[0]["promo_slider"]["size"]} />
                            )}
                        </React.Fragment>
                    )}
                    <Slide bottom duration={500}>
                        <RestaurantList slides={promo_slides.otherSlides} categories_slides={categories_slides} />
                    </Slide>
                    <Footer active_nearme={true} />
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => ({
    promo_slides: state.promo_slides.promo_slides,
    categories_slides: state.categories_slides.categories_slides,
    user: state.user.user,
    locations: state.locations.locations,
    language: state.languages.language
});

export default connect(
    mapStateToProps,
    { getPromoSlides, getCategoriesSlides, saveNotificationToken, getSettings, getSingleLanguageData }
)(Home);
