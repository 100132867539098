import { GET_CATEGORIES_SLIDER } from "./actionTypes";
import { GET_CATEGORIES_SLIDER_URL } from "../../configs";
import Axios from "axios";

export const getCategoriesSlides = location_name => dispatch => {
    Axios.post(GET_CATEGORIES_SLIDER_URL, {
        location_name: location_name
    })
        .then(response => {
            const categories_slides = response.data;
            return dispatch({ type: GET_CATEGORIES_SLIDER, payload: categories_slides });
        })
        .catch(function (error) {
            console.log(error);
        });
};
