import React, { Component } from "react";

import Mobile from "../../components/Mobile";
import { connect } from "react-redux";
import { getSettings } from "../../services/settings/actions";

import { getAllLanguages } from "../../services/translations/actions";
import { updateUserInfoGeneral } from "../../services/user/actions";

class App extends Component {
    componentDidMount() {
        //force update settings everytime
        this.props.getSettings();
        this.props.getAllLanguages();
        this._initMobilePushNotifications();
    }

    _initMobilePushNotifications() {
        const { user } = this.props;
        if (window.cordova && window.PushNotification) {
            console.log('init push notifications');
            const push = window.PushNotification.init({
                android: {
                },
                ios: {
                    alert: "true",
                    badge: true,
                    sound: 'false'
                },
                windows: {}
            });

            push.on('registration', (data) => {
                console.log("update push notification_id");
                this.props.updateUserInfoGeneral(user.data.id, user.data.auth_token, { nif: user.data.nif, push_token: data.registrationId, device_type: window.cordova.platformId });
            });

            push.on('notification', (data) => {
                console.log("push date=>", data);
                if (window.navigator.notification) {
                    navigator.notification.alert(
                        data.message,         // message
                        null,                 // callback
                        data.title,           // title
                        'Ok'                  // buttonName
                    );
                }
                else {
                    alert(data.message);
                }
            });

            push.on('error', (e) => {
                console.log(e.message);
            });
        }
    }

    render() {
        return (
            <React.Fragment>
                <Mobile languages={this.props.languages} />
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => ({
    settings: state.settings.settings,
    user: state.user.user,
    notification_token: state.notification_token.notification_token,
    languages: state.languages.languages
});

export default connect(
    mapStateToProps,
    { getSettings, getAllLanguages, updateUserInfoGeneral }
)(App);
